<template>
  <v-app>
    <v-main>
      <v-container
        class="text-center fill-height"
      >
        <v-row align="center">
          <v-col>
            <v-img height="500" src="@/assets/pnskomputer.svg" contain></v-img>
            <h1 class="display-2 primary--text">
              LOGOUT
            </h1>
            <p class="overline">Keluar dari aplikasi dalam {{timer / 1000}} detik.</p>
            <p>Jika anda tidak menginginkan tindakan ini kemungkinan terdapat perangkat berbeda mengkases akun yang sama</p>
            <v-btn
              color="primary"
              @click="gotoPortal()"
              outlined
            >
              Kembali ke halaman utama
            </v-btn>
            <v-btn
              color="red"
              @click="gotoLogin()"
              outlined
            >
              Kembali ke halaman login
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import router from '@/router'
  import store from '@/store'
  export default {
    name: 'Logout',
    data(){
      return{
        timer:10000, // dalam milisecond
        timeout:{}
      }
    },
    mounted(){
      this.countdown()
    },
    methods:{
      countdown(){
        this.timeout = setTimeout(() => {
          console.log('call timer', this.timer)
          if (this.timer > 0 ){
            this.timer -= 1000
            this.countdown()
          }else {
            store.commit('user/SET_ONGOING', false)
            router.push('/')
          }
        }, 1000)
      },
      gotoPortal(){
        clearTimeout(this.timeout)
        store.commit('user/SET_ONGOING', false)
        router.push('/')
      },
      gotoLogin(){
        clearTimeout(this.timeout)
        store.commit('user/SET_ONGOING', false)
        router.push('/login')
      }
    },
  }
</script>
